<template>
    <modal name="cupomHistorico" height="auto" width="900" :scrollable="true" :clickToClose="true">
        <CloseModal :close="close"/>
        <div class="modalbox">
            <div class="modalbox-content">
                <CTabs variant="tabs" class="nav-tabs-boxed">
                    <CTab title="Histórico de Uso">
                        <div class="table-responsive">
                            <datatable
                                :columns="table.columns"
                                :data="historico"
                                class="table"
                            >
                            </datatable>
                        </div>
                    </CTab>
                </CTabs>
            </div>
        </div>
    </modal>
</template>

<script>
  import CloseModal from "../../components/CloseModal";

  export default {
    name: "cupomHistorico",
    components: {
      CloseModal
    },
    props: [
      "historico",
      "close"
    ],
    data() {
      return {
        table: {
          columns: [
            {label: "Cliente", field: "cliente", align: "center", sortable: false},
            {label: "Data Uso", field: "datauso", align: "center", sortable: false},
            {label: "Pedido", field: "pedido_id", align: "center", sortable: false},
            {label: "Valor Pedido", field: "valortotal", align: "center", sortable: false},
            {label: "Desconto", field: "desconto", align: "center", sortable: false},
          ]
        }
      };
    },
    methods: {},
  };
</script>
